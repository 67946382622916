.d-flex {
  display: flex;
}

.call_us-container {
  justify-content: space-between;
  height: 228px;
  padding: 0 15px 0 35px;
  border-radius: 4px;
  border: 10px solid #fff;
  background: #dde5ff;
  display: flex;
}

.content-box {
  padding-top: 42px;
  color: #2c4db8;
}

.content-box h3 {
  font-weight: 700 !important;
  margin: 0 !important;
}

.phone {
  align-items: center;
}

.call-icon {
  width: 43px;
  height: 39px;
  margin-bottom: 0;
  padding-right: 10px;
  animation: phoneAnimate 2s linear infinite;
}

.call-us {
  font-size: 26px;
  font-weight: 900;
  color: #fd5133;
  line-height: 24px;
}

.call-us:hover,
.call-us:focus {
  font-size: 26px;
  font-weight: 900;
  color: #fd5133;
  line-height: 24px;
}

.call-below-text {
  margin: 0;
  font-size: 13px;
}

@keyframes phoneAnimate {

  5%,
  50% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.9);
  }

  15% {
    transform: scale(1.15);
  }

  20% {
    transform: scale(1.15) rotate(-5deg);
  }

  25% {
    transform: scale(1.15) rotate(5deg);
  }

  30% {
    transform: scale(1.15) rotate(-3deg);
  }

  35% {
    transform: scale(1.15) rotate(2deg);
  }

  40% {
    transform: scale(1.15) rotate(0);
  }
}

.call-center-girl {
  width: 195px;
  padding-top: 12px;
  position: relative;
  overflow: hidden;
}

.girl-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 1;
  transition: opacity 500ms ease 0s;
}

/* .modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: auto !important;
    width: fit-content !important;
  } */
.modal {
  opacity: 1;
}

.call-modal {
  max-width: 925px !important;
  /* position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-in-out;
  height: auto !important;
  width: inherit !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.call_modal-container {
  justify-content: space-between;
  padding: 0 35px 0 38px;
  border-radius: 4px;
  display: flex;
}

.call-modal .modal-content {
  border: 12px solid #fff;
  background: #c0ecff;
  text-align: left;
}

.modal-header {
  border-bottom: none !important;
}

.call-modal-content {
  display: flex;
}

.modal-content-popup {
  flex-grow: 0;
  flex-basis: calc(60% + 74px);
  padding-top: 50px;
  margin-right: 8px;
}

.modal-call-center {
  flex: 0 0 29%;
  min-height: 300px;
  background-size: 78%;
  position: relative;
}

.modal-content-box .phone-deal-description {
  color: #008dca;
  font-weight: 400;
}

.modal-content-box h2 {
  font-size: 46px;
  font-weight: 700;
  color: #0178ab;
  margin: 0;
}

.call-us-text {
  font-size: 38px;
  font-weight: 400;
  color: #008dca;
}

.call-us-text-2 {
  font-size: 38px;
  font-weight: 500;
  color: #008dca;
}

.phone-only-text {
  font-size: 22px;
}

.modal-call-icon {
  width: 73px !important;
  height: 60px !important;
}

.modal-call-us {
  font-size: 43px !important;
}

/* .modal-call-us:hover, .modal-call-us:focus{
    font-size: 43px !important;
} */
.phone-help {
  padding: 10px 0 30px;
}

.phone-girl-img {
  width: 282px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.modal-bottom-text {
  color: #008dca;
  font-size: 16px;
  font-weight: 400;
}

.refresh-modal {
  width: 600px !important;
}

.refresh-modal .modal-call-center {
  min-height: 60px !important;
}

.refresh-modal .modal-content-box h2 {
  font-size: 32px;
}

.refresh-modal .modal-content-popup {
  padding-top: 15px !important;
}

.refresh-modal .modal-call-center button {
  width: 250px;
  margin: 0 auto;
}

@media (min-width: 480px) and (max-width: 768px) {
  .call_modal-container {
    padding: 0 15px 0 15px;
  }

  .modal-content-popup {
    padding-top: 20px;
  }

  .modal-content-box h2 {
    font-size: 36px;
  }

  .call-us-text {
    font-size: 32px;
  }

  .call-us-text-2 {
    font-size: 32px;
  }

  .modal-call-us {
    font-size: 32px !important;
  }

  .modal-bottom-text {
    font-size: 12px;
  }

  .phone-help {
    padding: 10px 0 30px;
  }

  .phone-girl-img {
    width: 218px;
  }
}

@media (max-width: 480px) {
  .call-modal {
    margin: 0 !important;
    /* max-width: 375px !important; */
    /*    height: 529px; */
  }

  .call_modal-container {
    padding: 0 15px 0 15px;
  }

  .modal-content-popup {
    padding-top: 10px;
  }

  .modal-content-box h2 {
    font-size: 18px;
  }

  .call-modal-content {
    display: block;
  }

  .modal-call-center {
    min-height: 0;
    /* display: none; */
  }

  .refresh-modal .modal-call-center button {
    width: 190px;
  }

  .call-us-text {
    font-size: 16px;
  }

  .call-us-text-2 {
    font-size: 18px;
  }

  .modal-call-icon {
    width: 60px !important;
    height: 40px !important;
  }

  .modal-call-us {
    font-size: 24px !important;
  }

  .phone-help {
    padding: 10px 0 10px;
  }

  .phone-girl-img {
    width: 200px;
    padding-top: 0px;
    float: right;
  }

  .modal-bottom-text {
    font-size: 12px;
  }

  .call-us {
    font-size: 18px;
    font-weight: 800;
  }

  .call-below-text {
    font-size: 10px;
  }
}

@media (max-width: 375px) {
  .call-modal {
    margin: 0 !important;
    max-width: 325px !important;
  }
}

/*-------card style-------*/
.popup-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #2dace3;
  border-radius: 4px !important;
  margin-bottom: 10px;
}

.popup-card-header,
.phone-deal-card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #2dace3;
  border-bottom: 1px solid #2dace3;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  border-radius: 4px 4px 0 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 2.25rem;
  background: white;
}

.card-title {
  margin-bottom: 0.75rem;
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  color: #005e86;
  margin-top: 0 !important;
}

@media (max-width: 600px) {
  .card-body {
    padding: 1.25rem !important;
  }

  .card-title {
    font-size: 18px;
  }

  .mob-card-header,
  .mob-card-text {
    display: none;
  }

  .refresh-modal {
    width: 100% !important;
  }

  .call-modal .modal-content {
    width: 80% !important;
  }

  .refresh-modal .modal-content-box h2 {
    font-size: 22px !important;
  }
}

@media (max-width: 480px) {
  .popup-card .card-body {
    overflow: hidden;
  }

  .popup-card .card-title,
  .popup-card.static-card .card-title {
    font-size: 14px;
    line-height: 20px;
  }

  .popup-card .contact-girl-img {
    width: 120px;
    right: -50px;
  }

  .popup-card .inner-contact-image .coupon-container {
    right: -25px;
    zoom: 45%;
    bottom: 0;
  }

  .popup-card .card-body .phone {
    zoom: 80%;
  }
}

.card-text:last-child {
  margin-bottom: 0;
  color: #0172a3;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 23px;
}

.card-call-icon {
  width: 58px !important;
  height: 48px !important;
}

@media (max-width: 480px) {
  .card-call-icon {
    width: 40px !important;
    height: 30px !important;
  }
}

/*-------static popup-------*/
.static-card .card-title {
  font-size: 32px;
}

.static-popup {
  padding: 10px;
  text-transform: none;
  display: flex;
  /* background-color: #0085be; */
  background: #1879c0 !important;
  position: relative;
  border-radius: 5px;
  margin-bottom: 10px;
}

.static-label {
  position: absolute;
  top: 10px;
  right: -6px;
  padding: 4px 10px 3px;
  height: 22px;
  border-radius: 0 2px 0 0;
  box-sizing: border-box;
  background-color: #ff8000;
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
}

.static-label::before {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-color: transparent #ff8000 transparent transparent;
  border-width: 11px 8px 11px 4px;
}

.static-label:after {
  right: 0;
  top: 100%;
  border-color: #ff8000 transparent transparent #ff8000;
  border-width: 3px;
}

.static-label:after,
.static-label::before {
  position: absolute;
  border-style: solid;
  content: "";
}

.offer-call-icon {
  width: 42px;
  height: 42px;
  background: #fff;
  color: #0085be;
  border-radius: 100%;
  text-align: center;
  font-size: 24px;
  vertical-align: middle;
  line-height: 43px;
  margin-right: 15px;
}

.save-text {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
}

.call-no-text {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.call-no-text:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .save-text {
    font-size: 14px;
    font-weight: 500;
  }

  .call-no-text {
    font-size: 16px;
    font-weight: 500;
  }

  .offer-call-icon {
    width: 32px;
    height: 32px;
    font-size: 18px;
    line-height: 34px;
  }
}

@media (max-width: 480px) {
  .static-popup .save-text {
    font-size: 12px;
    font-weight: 500;
  }

  .static-popup .call-no-text {
    font-size: 12px;
    font-weight: 500;
  }

  .static-popup .offer-call-icon {
    width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    margin-right: 5px;
    position: absolute;
    top: 13px;
    left: 10px;
  }

  .static-popup .static-label {
    font-size: 10px;
    padding: 4px 6px 3px;
  }

  .static-popup .static-popup-content {
    padding-right: 50px;
    padding-left: 30px;
  }
}

/*-------------phone deal card---------*/
.phone-deal-card {
  border: 1px solid #f95b17;
  position: relative;
}

.phone-deal-card-header {
  background-color: #f95b17;
  border-bottom: 1px solid #f95b17;
}

.phone-deal-card-body {
  padding: 15px 30px 15px 15px;
  /* display: flex; */
  align-items: center;
}

.phone-deal-card-body .theme-search-results-item-book {
  padding: 20px 0 0 0px;
}

.border-right {
  border-right: 1px solid #f0f0f0;
}

.phone-deal-total-price {
  font-weight: 700;
  color: #464646;
}

.Phone-deal-price {
  font-size: 20px;
  font-weight: 800;
}

.call-now-btn {
  background-color: #f95b17;
  border: 1px solid #f95b17;
  border-radius: 3px;
  color: #fff;
  font-size: 15px;
  padding: 5px 12px;
}

@media (max-width: 600px) {
  .phone-deal-card-header {
    font-size: 12px;
  }

  .phone-deal-card-body {
    padding: 15px;
    /* display: flex; */
  }

  .border-left {
    border-left: none;
  }

  .call-now-button {
    margin-bottom: 15px;
  }
}

@media (max-width: 786px) {
  .border-left {
    padding-left: 5px;
  }
}

/*-----ribbon-phone deal----*/
.ribbon {
  width: 130px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 10px 0;
  background-color: #3498db;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
}

.ribbon-top-right {
  top: -1px;
  right: -1px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

@media (max-width: 600px) {
  .ribbon span {
    font-size: 12px;
    font-weight: 500;
    padding: 6px 0;
  }
}

body.modal-open {
  /*overflow: hidden !important;*/
  /* overflow: visible !important;*/
  /*position: absolute !important;*/
}

/****-------------bottom static card ----------***/
.bottom-static-card-content {
  display: flex;
}

.bottom-static-card-content .inner-card-content {
  flex-grow: 0;
  flex-basis: 65%;
  margin-right: 8px;
}

.bottom-static-card-content .inner-contact-image {
  flex: 0 0 29%;
  background-size: 65%;
  position: relative;
}

.inner-contact-image .coupon-container {
  border: 2px dashed;
  position: absolute;
  bottom: -25px;
  padding: 10px 15px;
  background: white;
  z-index: 12;
}

.inner-contact-image .coupon-container h2 {
  margin-top: 0;
  font-weight: normal;
  color: red;
  font-size: 24px;
  text-align: center;
}

.inner-contact-image .coupon-container h3 {
  font-size: 16px;
  text-align: center;
  font-weight: normal;
  margin-top: 0;
  color: black;
}

.inner-contact-image .coupon-container .floating-div {
  position: absolute;
  bottom: -15px;
  background: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.contact-girl-img {
  width: 260px;
  position: absolute;
  bottom: -20px;
  right: -35px;
}

.cancellation-chip {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: green;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
}