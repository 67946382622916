.flight-details {
  right: 33.3333333%;
}

.trip-summary {
  left: 66.666667%;
}

._pt-desk-70 .flight-detail-heading {
  font-size: 2.5rem;
}

.pax-details {
  display: inline;
}

.srv-validation-message {
  color: #a94442;
}

@media (max-width: 600px) {
  .flight-details {
    right: auto;
  }

  .trip-summary {
    left: auto;
  }
}

.web-only {
  display: inherit;
}

.mobile-only {
  display: none !important;
}

.mobile-only-block {
  display: none !important;
}

@media (max-width: 525px) {
  .mobile-only {
    display: initial !important;
  }

  .mobile-only-block {
    display: block !important;
    font-size: 12px;
    text-align: center;
  }

  .web-only {
    display: none !important;
  }
}

.w-full {
  width: 100%;
}

.flight-detail-departure::before {
  bottom: 0.95rem;
  width: 2.5rem;
  right: 1rem;
}

.flight-detail-heading {
  padding-bottom: 8px;
}

.traveller-detail-heading {
  padding-bottom: 8px;
  margin-top: 25px;
}

.continue-button {
  padding: 7px 12px;
  font-size: 16px;
  float: right;
  background: #f95b17;
  margin: 15px 0;
  width: 100%;
}

.continue-button a:hover,
.continue-button a:focus {
  color: #fff;
}

.back-to-result-button {
  background: none;
  border: none;
  text-decoration: underline;
}

.back-to-result-button a {
  color: #fff;
}

.trip-summary-card-header {
  background-color: #b9eaff;
  border-bottom: 1px solid #b9eaff;
  color: #333;
  font-size: 18px;
  font-weight: 600;
}

.trip-summary-card-header img {
  margin-right: 10px;
}

.fare-title {
  margin-top: 10px;
  color: #000;
}

.fare-content {
  padding-left: 15px;
  color: #3e3e3e;
}

.fare-content .tax {
  color: #666;
  font-size: 10px;
}

.other-fare {
  color: #666;
  font-size: 16px;
}

.other-fare h3 {
  font-size: 16px;
  margin-top: 0;
  color: #666;
}

.total-fare {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.total-fare h3 {
  margin-top: 0;
  font-weight: 600;
  color: #333;
}

.hr_divider {
  border-top: 1px dashed #9b9b9b;
  margin-top: 15px;
  margin-bottom: 15px;
}

.passenger-detail-card:hover {
  box-shadow: 0 0 10px 0 #d1d1d1;
  /* border: 1px solid #e6e6e6; */
  border-radius: 4px;
}

.traveller-form-heading {
  font-size: 18px !important;
  margin: 0;
}

.traveller-form {
  width: 100%;
  max-width: 39.25rem;
}

.list-form_label {
  font-size: 14px;
  margin: auto 10px;
  width: 10%;
}

.traveller-form .label-text {
  font-size: 12px !important;
}

.text-secondary {
  color: #7d7d7d;
}

@media (max-width: 786px) {
  .trip-summary-card .col-8 {
    width: 65%;
    display: inline-block;
  }

  .trip-summary-card .col-4 {
    width: 20%;
    display: inline-block;
  }

  .list-form_label {
    width: 100%;
  }

  ._one-line-flex {
    display: block;
  }

  .flight-detail-heading {
    font-size: 15px !important;
    padding: 0 !important;
  }

  .traveller-detail-heading {
    padding-bottom: 0px;
    margin-top: 20px;
    font-size: 15px !important;
  }

  .back-to-result-button {
    font-size: 14px;
  }

  .traveller-form-heading {
    font-size: 14px !important;
  }

  .traveller-form .label-text {
    font-size: 10px !important;
  }

  .trip-summary-card-header {
    font-size: 12px;
    padding: 5px;
  }

  .continue-button {
    margin: 10px 0;
  }

  .pax-details {
    display: block;
  }
}

.terms-heading {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.terms-heading i {
  font-size: 18px;
}

.terms-accepts {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.terms-accepts input {
  width: 25px;
  min-width: 20px;
  height: 25px;
  margin: -4px 0 0;
}

.bagg-allowance,
.st-bagg-allowance {
  font-size: 11px;
  font-weight: 600;
  color: #333;
}

.st-bagg-allowance {
  margin: 5px;
  font-weight: 600;
  text-align: center;
}