.center_icons {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center_icons img {
  height: 50px;
  object-fit: contain;
}

.center_icons a img {
  height: 35px;
  margin: 0 0 0 12px;
}

.center_icons img:first-child {
  height: 35px;
}

.navbar-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center_icons_mobile {
  display: none;
}

.navbar {
  background: #082f4f;
}

@media (max-width: 700px) {
  ._pv-mob-90 {
    padding-top: 170px !important;
    padding-bottom: 90px !important;
  }

  .navbar .container {
    padding: 0;
  }

  .navbar .navbar-inner {
    display: block;
    padding: 0 15px;
  }

  .center_icons {
    display: none;
  }

  .center_icons_mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #bbb3b3;
    width: 100%;
    padding: 10px 0;
  }

  .center_icons_mobile a img {
    height: 30px;
    margin: 0 0 0 13px;
  }

  .center_icons_mobile img {
    height: 56px;
    object-fit: contain;
  }
}