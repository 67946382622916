.holiday-container {
  padding: 130px 65px 30px 65px;
}
.holiday-container h3 {
  margin-top: 40px;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 21px 0;
}

.h-card {
  width: 49%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.4s;
  display: flex;
  margin-bottom: 21px;
  align-items: center;
  min-height: 155px;
}

.h-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.h-card img {
  width: 201px;
  object-fit: cover;
  height: 154px;
}
.h-card .container {
  display: flex;
  padding: 4px 16px;
  flex-direction: column;
}
.h-card .container p {
  color: rgb(114, 114, 114);
  cursor: pointer;
  display: block;
  font-family: Neuville, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}
.h-card .container h4 {
  color: rgb(51, 51, 51);
  font-size: 15px;
  margin: 0;
  line-height: 24px;
}
.h-card a {
  color: rgb(6, 141, 200);
  font-weight: 600;
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  align-items: end;
}
.holiday-container .theme-page-section-blue {
  color: white;
  padding: 15px;
  text-align: center;
}
.why-us-section {
  background: white;
  margin: 20px 0;
  border-radius: 8px;
  border: 1px solid #dde1dd;
  padding: 12px;
  width: 65%;
}
.contact-section {
  background: white;
  margin-left: 20px;
  border-radius: 8px;
  border: 1px solid #dde1dd;
  width: 35%;
  min-height: 200px;
}
.contact-section ul {
  padding: 0;
  margin: 0;
}
.contact-section li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0ecec;
  padding: 10px 17px;
}
.contact-section li i {
  color: #f95b17;
  font-size: 30px;
}
.contact-section li a {
  color: black;
  font-size: 18px;
  font-weight: 600;
}

.contact-section .call-below-text {
  font-size: 15px;
}
.why-us-section h4 {
  font-weight: 600;
  line-height: 24px;
  font-size: 18px;
  color: #f95b17;
}
.why-us-section ul {
  padding-left: 13px;
}
.why-us-section li {
  line-height: 24px;
  color: rgb(0, 0, 0);
  font-family: Neuville, sans-serif;
  font-size: 15px;
}
.last-section {
  display: flex;
  align-items: center;
}
@media (max-width: 700px) {
  .holiday-container {
    padding: 130px 30px 30px 30px;
  }
  .h-card {
    width: 100%;
    flex-direction: column;
  }
  .h-card img {
    width: 100%;
    height: 200px;
  }
  .last-section {
    flex-wrap: wrap;
    width: 100%;
  }
  .contact-section {
    height: 100%;
    width: 100%;
    margin: 0;
  }
  .why-us-section {
    width: 100%;
  }
}
@media (min-width: 701px) and (max-width: 1000px) {
  .h-card {
    flex-direction: column;
  }
  .h-card img {
    width: 100%;
    height: 200px;
  }
  .h-card .container {
    width: 100%;
  }
}
