.container-cookie {
  z-index: 99999999999 !important;
}

.navbar-nav-item-phone .phone-number-text {
  color: rgba(255, 255, 255) !important;
  padding-right: 50px;
}

.brand-logo img {
  width: auto;
  height: 50px;
}

.navbar-nav-item-phone-icon {
  font-size: 25px;
  margin-right: 5px;
  position: absolute;
  left: -25px;
}

.overlay {
  background: rgb(0 0 0 / 60%);
}

.save_upto-overlay {
  background: rgb(0 0 0 / 24%);
  padding-left: 15px;
  padding-right: 15px;
}

.hero-desk {
  height: calc(100vh - 60px);
}

@media (max-width: 576px) {
  .navbar-nav-item-phone-icon {
    font-size: 22px;
    left: -30px;
  }

  .navbar-nav-item-phone .phone-number-text {
    display: none !important;
  }

  .navbar-nav-item-register {
    display: none !important;
  }
}

@media (max-width: 600px) {
  .brand-logo img {
    height: 45px;
  }

  .hero-desk {
    height: 940px;
  }

  _pv-mob-60 {
    position: absolute !important;
    top: 45% !important;
    height: auto !important;
  }
}

@media (max-width: 375px) {
  .navbar-header {
    padding-top: 9px;
  }

  .brand-logo img {
    height: 35px;
  }
}

.middle-search-bar {
  margin: 0 8px;
}

.input-placeholder {
  position: absolute;
  left: 42px;
  top: 4px;
  z-index: 1;
  color: #b4b4b4;
  font-size: 12px;
  font-style: italic;
  line-height: 1;
  font-weight: 500;
}

.date-placeholder {
  position: absolute;
  left: 37px;
  top: 4px;
  z-index: 1;
  color: #b4b4b4;
  font-size: 12px;
  font-style: italic;
  line-height: 1;
  font-weight: 500;
}

.search-option-text {
  display: flex;
  max-width: 100%;
}

.search-option-name {
  width: 200px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  max-width: fit-content;
  text-align: center;
}

@media (max-width: 425px) {
  .search-option-name {
    width: 180px !important;
    white-space: normal;
    text-overflow: ellipsis !important;
    max-width: 100%;
    text-align: center;
  }
}

.search-option-tag {
  color: #808080;
  font-size: 12px;
  margin-left: 10px;
}

.search-option-menu {
  color: #808080;
  font-size: 14px;
  margin-left: 10px;
}

@media (max-width: 600px) {
  .middle-search-bar {
    margin: 0;
  }
}

@media (min-width: 600.5px) and (max-width: 768px) {
  .middle-search-bar {
    margin: 0 8px 0 0;
  }

  .hero-desk {
    height: 540px;
  }

  .theme-search-area-title-lg {
    font-size: 40px;
  }

  .search-button {
    margin-left: 8px !important;
  }
}

@media (min-width: 768.5px) and (max-width: 991.5px) {
  .middle-search-bar {
    margin: 0 8px 0 0;
  }

  .hero-desk {
    height: 540px;
  }

  .theme-search-area-title-lg {
    font-size: 40px;
  }

  .search-button {
    margin-left: 8px !important;
  }
}

.bulletpoint-card {
  background: #fff;
  padding: 10px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  color: #191919;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .bulletpoint-card {
    width: 100%;
    font-size: 11px;
  }

  .navbar-nav .open .dropdown-menu {
    position: absolute !important;
    background-color: #fff !important;
  }
}

.bulletpoint-icon {
  width: 36px !important;
  margin-right: 15px;
}

.bulletpoint-icons {
  color: #fff;
  display: inline-table;
  font-size: 22px;
  /* background-color: #2C4DB8; */
  border-radius: 100%;
  width: 44px;
  height: 44px;
  padding: 0 10px;
  text-align: center;
  line-height: 44px;
  margin-right: 15px;
}

.depart-icon {
  /*  transform: rotate(17deg); */
  width: 26px;
  height: 26px;
  margin: 16px 0px 9px 10px;
}

.traveller-icon {
  width: 22px;
  height: 22px;
  margin: 18px 0px 17px 10px;
}

.fly_now {
  padding-left: 50px;
}

.fly-now-arrow {
  padding-top: 9px;
}

.traveller-btn {
  padding-top: 8px;
  font-weight: 600;
  color: #333 !important;
}

.flying-from-input {
  border-radius: 3px 0 0 3px;
}

.flying-to-input {
  border-radius: 0 3px 3px 0;
}

@media (min-width: 480px) and (max-width: 768px) {
  .fly-now-arrow {
    padding-top: 14px;
  }
}

@media (min-width: 600.5px) and (max-width: 768.5px) {
  .fly_now {
    padding-left: 15px;
  }
}

@media (max-width: 600px) {
  .fly_now {
    padding-left: 10px;
    padding-right: 0;
  }

  .fly_now img {
    height: 45px;
  }

  .d-inline {
    display: block !important;
    font-size: 13px;
    margin-top: 0;
    font-weight: 500;
    margin-bottom: 0;
  }

  .fly-now-arrow {
    display: none;
  }

  .traveller-btn {
    padding-top: 9px;
  }

  .flying-from-input {
    border-radius: 3px 3px 0 0;
  }

  .flying-to-input {
    border-radius: 0 0 3px 3px;
  }

  .sm-w-full {
    width: 100% !important;
  }
}

@media (max-width: 320px) {
  .d-inline {
    font-size: 10px;
  }
}

/**--------------***/
.custom-radio-wrap {
  /*     padding: 20px;
    margin-bottom: 20px; */
}

.custom-radio-wrap form .form-group {
  margin-bottom: 10px;
}

.custom-radio-wrap form .form-group:last-child {
  margin-bottom: 0;
}

.custom-radio-wrap form .form-group label {
  -webkit-appearance: none;

  border: 2px solid #fff !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05) !important;
  padding: 7px !important;
  border-radius: 50px !important;
  display: inline-block !important;
  position: relative !important;
  vertical-align: middle !important;
  cursor: pointer !important;
  margin-bottom: 0 !important;
}

.custom-radio-wrap form .form-group .label-text {
  vertical-align: middle !important;
  cursor: pointer !important;
  padding-left: 8px !important;
}

.custom-radio-wrap form .form-group input {
  display: none !important;
  cursor: pointer !important;
}

.custom-radio-wrap form .form-group input:checked+label {
  border: 2px solid #fff !important;
}

.custom-radio-wrap form .form-group input:checked~.label-text {
  color: #fff !important;
  font-weight: 500 !important;
}

.custom-radio-wrap form .form-group input:checked+label:after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50px !important;
  position: absolute !important;
  top: 2px;
  left: 2px;
  background: #2dace3 !important;
  /* background: #ff6c2d; */
  /* box-shadow: inset 0px 0px 10px rgba(0,0,0,0.3); */
  text-shadow: none;
  font-size: 32px;
}

/**----------***/
@media (max-width: 600px) {
  .inspiration-icon-label {
    font-size: 13px;
    font-weight: 500 !important;
  }

  .feature-list {
    /* border-top: 1px solid #d4d4d4; */
    border-bottom: 1px solid #d4d4d4;
  }
}

.feature-card-icon {
  /* color:#2dace3 ; */
  color: #f95b17;
  font-size: 28px;
  /* margin-right: 20px; */
  padding: 13px 0;
}

.feature-card {
  background: #f7f7f7;
}

/* .feature-wrap-fade-white:hover{
    -webkit-box-shadow: 0 15px 40px rgb(154 172 222 / 73%), 0 7px 12px rgb(154 172 222 / 11%);
    box-shadow: 0 15px 40px rgb(154 172 222 / 73%), 0 7px 12px rgb(154 172 222 / 11%);
    border:none;
} */
.feature-icon-circle {
  width: 60px;
  height: 60px;
  background: #fff0ea;
  border-radius: 100%;
  margin-right: 15px;
  border: 4px solid #fff;
  box-shadow: 2px 0px 5px -1px #ccc;
}

@media (min-width: 1025px) {
  .search-button {
    margin-left: 8px !important;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .feature-list {
    margin-bottom: 16px;
  }
}

/*------newsletter-----*/
.newsletter {
  padding-top: 0;
}

.newsletter .content {
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
}

.newsletter .content h2 {
  margin-bottom: 40px;
}

.newsletter .content .form-control {
  height: 48px;
  border-color: #caefff;
  border-radius: 3px 0px 0px 3px;
  box-shadow: none;
  font-size: 16px;
}

.newsletter .content.form-control:focus {
  box-shadow: none;
  border: 2px solid #243c4f;
}

.newsletter .input-group .form-control:focus {
  z-index: 2;
}

.newsletter .content .btn {
  min-height: 48px;
  border-radius: 0;
  background: #2dace3;
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  border-radius: 0 3px 3px 0px;
}

.newsletter-loader .MuiCircularProgress-root {
  width: 24px !important;
  height: 24px !important;
}

.newsletter-greeting-text {
  margin-top: 10px;
  color: green;
}

.DateInput {
  width: 45% !important;
}

.MuiInputBase-input {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #333 !important;
}

.MuiFormControl-fullWidth {
  background-color: #fff !important;
  border-radius: 6px !important;
}

.MuiInputBase-fullWidth {
  height: 55px !important;
  background-color: #fff !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 25px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input {
  padding: 9px 0 !important;
}

.MuiFilledInput-underline:before {
  border-bottom: none !important;
}

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  padding-left: 0px !important;
  padding-top: 11px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  padding-right: 50px !important;
}

.MuiFilledInput-underline:after {
  border-bottom: 2px solid #2dace3 !important;
}

.MuiAutocomplete-paper {
  margin: 4px 0 0 -39px !important;
  font-size: 14px !important;
  font-family: "Gotham", sans-serif !important;
  width: 360px !important;
}

@media (max-width: 425px) {
  .MuiAutocomplete-paper {
    margin: 4px 0 0 -44px !important;
    width: 305px !important;
    max-width: 305px !important;
  }
}

.MuiInputBase-root {
  font-family: "Gotham", sans-serif !important;
}

.MuiFilledInput-root {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.MuiAutocomplete-option:hover,
.MuiAutocomplete-option:focus {
  color: #2dace3 !important;
}

.MuiAutocomplete-option[data-focus="true"] {
  color: #2dace3 !important;
}

.DateRangePickerInput {
  height: 55px;
}

.DateInput {
  height: 55px;
  text-align: center;
  background: none !important;
}

.DateInput_1 {
  border-left: #b4b4b4 !important;
}

.DateInput_input {
  padding: 6px 5px 0 37px !important;
  background-color: transparent !important;
  line-height: 53px !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #333 !important;
}

.DateInput_input::placeholder {
  visibility: hidden;
}

.DateRangePickerInput__withBorder {
  border: none !important;
}

/* .DateRangePickerInput_arrow {
    display: none !important;
    vertical-align: middle;
    color: #484848;
    visibility: hidden !important;
} */
.DateInput_input__focused {
  height: 55px !important;
  border-bottom: 2px solid #2dace3 !important;
}

.DateInput_input::placeholder {
  position: absolute;
  left: 42px;
  top: 4px;
  z-index: 1;
  color: #b4b4b4;
  font-size: 12px;
  font-style: italic;
  line-height: 1;
  font-weight: 500;
}

.calendar-icon {
  line-height: 45px;
  text-align: center;
  font-size: 22px;
  color: #2dace3;
  opacity: 0.86;
  position: absolute;
  top: 8px;
}

.CalendarDay__hovered_span:active {
  background: #b2c4ff !important;
  border: 1px double #b2c4ff !important;
  color: #2dace3 !important;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2c4ff !important;
  border: 1px double #b2c4ff !important;
  color: #2dace3 !important;
}

.CalendarDay__selected_span {
  background: #b2c4ff !important;
  border: 1px double #b2c4ff !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #2dace3 !important;
  border: 1px double #2dace3 !important;
}

/**----------social links icon------*/
.middle-footer .footer-logo {
  margin: auto;
}

.footer-payment {
  padding-top: 4px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
}

.payment-section .footer-icons,
.followus-section .footer-icons {
  padding-top: 10px;
}

.footer-payment-img {
  max-width: 40px;
  max-height: 30px;
  margin-right: 8px;
  object-fit: contain;
}

.footer-follow_us {
  margin-right: 8px;
  font-weight: 500;
  font-size: 14px;
}

.paddingLeft-30 {
  padding-left: 30px;
}

.destination-list {
  padding-left: 0;
  text-align: left;
}

.footer-logo-link-ul {
  display: flex;
  justify-content: center;
  list-style: none;
}

.atol,
.financial,
.secure,
.bta {
  height: 60px;
  margin-right: 15px;
}

.sagepay {
  height: 40px;
  margin-right: 15px;
}

.tta,
.travelaware,
.flynow {
  height: 45px;
  margin-right: 15px;
}

img.travelaware {
  height: 60px;
}

.followus-section {
  float: right;
}

@media (max-width: 768px) {
  .middle-footer {
    margin-top: 25px;
  }

  .payment-section {
    justify-content: center;
  }

  .footer-logo-link-ul {
    padding-left: 0;
    margin-top: 20px;
    flex-wrap: wrap;
  }

  .destination-list {
    padding-left: 0;
    text-align: center;
  }

  .footer-logo-link-ul li {
    margin: auto 5px;
    padding: 10px 0;
  }

  .footer-logo-link-ul li img {
    width: auto;
    height: 43px;
    margin: auto;
  }

  .footer-loader-logos li img {
    height: 66px !important;
  }

  .atol,
  .financial,
  .secure,
  .bta {
    height: 42px;
  }

  .sagepay {
    height: 22px;
  }

  .tta {
    height: 32px;
  }

  .flynow {
    height: 32px;
  }

  .travelaware {
    height: 42px;
  }

  .followus-section {
    float: unset;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .paddingLeft-30 {
    padding-left: 0px;
  }

  .destination-inner-section {
    padding-top: 0;
  }

  .footer-ul {
    margin-bottom: 0 !important;
  }

  .mob_theme-footer-sec {
    margin-top: 10px;
  }

  .newsletter {
    padding-top: 0 !important;
  }
}

.fb {
  background: #0d81bd;
  border: 1px solid #0d81bd;
}

.twtr {
  background: #2cace3;
  border: 1px solid #2cace3;
}

.ytube {
  background: #f53a19;
  border: 1px solid #f53a19;
}

.insta {
  background: #af3794;
  border: 1px solid #af3794;
}

.whatsapp {
  background: #03a84e;
  border: 1px solid #03a84e;
}

.footer-quicklinks {
  padding-top: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  flex-wrap: wrap;
}

.html-footer {
  padding: 30px;
  color: #333;
}

.MuiFilledInput-underline::after {
  margin-left: -29px;
}

.no-fee-icon {
  background-image: url("/assets/ficon/images/credit-card.png");
  background-size: 30px;
  background-repeat: no-repeat;
  margin: 11px 0px 0px 12px;
  padding: 15px;
}

.lowest-price {
  background-image: url("/assets/ficon/images/discount.png");
  background-size: 30px;
  background-repeat: no-repeat;
  margin: 10px 0px 0px 12px;
  padding: 20px;
}

.winning-support {
  background-image: url("/assets/ficon/images/medal.png");
  background-size: 30px;
  background-repeat: no-repeat;
  margin: 11px;
  padding: 15px;
}

.financial-protection {
  background-image: url("/assets/ficon/images/login.png");
  background-size: 30px;
  background-repeat: no-repeat;
  margin: 10px 0 0 13px;
  padding: 20px;
}

.SingleDatePicker {
  display: block !important;
}

.SingleDatePickerInput__withBorder {
  border: none !important;
  width: 100%;
}

.SingleDatePicker .DateInput {
  width: 100% !important;
}

.no-option-class {
  display: none;
}

.MuiTooltip-tooltip {
  font-size: 1.5rem !important;
  margin: 10px 0px !important;
}

/***** Notification ******/
.notification-modal {
  top: 50%;
  transform: translateY(-50%);
}

.notification-modal .modal-content {
  padding: 10px 20px;
}

.notification-modal .modal-header {
  padding: 0;
  border-bottom: 1px solid #00000040 !important;
  margin-bottom: 10px;
}

.notification-content h2 {
  margin-top: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0000002e;
  padding-bottom: 16px;
}

.notification-content h2 a {
  cursor: pointer;
}

.notification-modal .modal-title {
  display: flex;
  align-items: center;
  margin-top: 0;
}

.notification-modal .modal-title p {
  margin-right: 10px !important;
  margin-left: -5px;
}

.notification-button {
  padding: 5px;
  width: 75px;
  float: right;
  background: rgb(121, 191, 64);
  border: 1px solid;
}

.notification-icon {
  fill: currentColor;
  width: 20px;
  height: 20px;
  display: inline-block;
  font-size: 2.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  margin-top: 16px;
  background-size: 30px 30px;
  background-color: #fff;
  background-image: linear-gradient(45deg, #f4f4f4 25%, transparent 25%),
    linear-gradient(-45deg, #f4f4f4 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #f4f4f4 75%),
    linear-gradient(-45deg, transparent 75%, #f4f4f4 75%);
  background-position: 0 0, 0 15px, 15px -15px, -15px 0;
}

.notification-error-icon {
  color: red;
}

.notification-success-icon {
  color: rgb(121, 191, 64);
}

/*-----------save upto style -------*/
@media (max-width: 600px) {
  .save-upto-text {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .save-upto-subtext {
    font-size: 12px;
  }
}

/****------tablist-----------***/
@media (max-width: 480px) {
  .nav-mob-tabs>li {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 16px !important;
  }
}

@media (min-width: 325px) and (max-width: 375px) {
  .nav-mob-tabs>li {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 10px !important;
  }
}

@media (min-width: 600.5px) and (max-width: 767.5px) {

  .custom-col-xs-1,
  .custom-col-xs-2,
  .custom-col-xs-3,
  .custom-col-xs-4,
  .custom-col-xs-5,
  .custom-col-xs-6,
  .custom-col-xs-7,
  .custom-col-xs-8,
  .custom-col-xs-9,
  .custom-col-xs-10,
  .custom-col-xs-11,
  .custom-col-xs-12 {
    float: left;
  }

  .custom-col-xs-12 {
    width: 100%;
  }

  .custom-col-xs-11 {
    width: 91.66666667%;
  }

  .custom-col-xs-10 {
    width: 83.33333333%;
  }

  .custom-col-xs-9 {
    width: 75%;
  }

  .custom-col-xs-8 {
    width: 66.66666667%;
  }

  .custom-col-xs-7 {
    width: 58.33333333%;
  }

  .custom-col-xs-6 {
    width: 50%;
  }

  .custom-col-xs-5 {
    width: 41.66666667%;
  }

  .custom-col-xs-4 {
    width: 33.33333333%;
  }

  .custom-col-xs-3 {
    width: 25%;
  }

  .custom-col-xs-2 {
    width: 16.66666667%;
  }

  .custom-col-xs-1 {
    width: 8.33333333%;
  }

  .custom-col-xs-pull-12 {
    right: 100%;
  }

  .custom-col-xs-pull-11 {
    right: 91.66666667%;
  }

  .custom-col-xs-pull-10 {
    right: 83.33333333%;
  }

  .custom-col-xs-pull-9 {
    right: 75%;
  }

  .custom-col-xs-pull-8 {
    right: 66.66666667%;
  }

  .custom-col-xs-pull-7 {
    right: 58.33333333%;
  }

  .custom-col-xs-pull-6 {
    right: 50%;
  }

  .custom-col-xs-pull-5 {
    right: 41.66666667%;
  }

  .custom-col-xs-pull-4 {
    right: 33.33333333%;
  }

  .custom-col-xs-pull-3 {
    right: 25%;
  }

  .custom-col-xs-pull-2 {
    right: 16.66666667%;
  }

  .custom-col-xs-pull-1 {
    right: 8.33333333%;
  }

  .custom-col-xs-pull-0 {
    right: auto;
  }

  .custom-col-xs-push-12 {
    left: 100%;
  }

  .custom-col-xs-push-11 {
    left: 91.66666667%;
  }

  .custom-col-xs-push-10 {
    left: 83.33333333%;
  }

  .custom-col-xs-push-9 {
    left: 75%;
  }

  .custom-col-xs-push-8 {
    left: 66.66666667%;
  }

  .custom-col-xs-push-7 {
    left: 58.33333333%;
  }

  .custom-col-xs-push-6 {
    left: 50%;
  }

  .custom-col-xs-push-5 {
    left: 41.66666667%;
  }

  .custom-col-xs-push-4 {
    left: 33.33333333%;
  }

  .custom-col-xs-push-3 {
    left: 25%;
  }

  .custom-col-xs-push-2 {
    left: 16.66666667%;
  }

  .custom-col-xs-push-1 {
    left: 8.33333333%;
  }

  .custom-col-xs-push-0 {
    left: auto;
  }

  .custom-col-xs-offset-12 {
    margin-left: 100%;
  }

  .custom-col-xs-offset-11 {
    margin-left: 91.66666667%;
  }

  .custom-col-xs-offset-10 {
    margin-left: 83.33333333%;
  }

  .custom-col-xs-offset-9 {
    margin-left: 75%;
  }

  .custom-col-xs-offset-8 {
    margin-left: 66.66666667%;
  }

  .custom-col-xs-offset-7 {
    margin-left: 58.33333333%;
  }

  .custom-col-xs-offset-6 {
    margin-left: 50%;
  }

  .custom-col-xs-offset-5 {
    margin-left: 41.66666667%;
  }

  .custom-col-xs-offset-4 {
    margin-left: 33.33333333%;
  }

  .custom-col-xs-offset-3 {
    margin-left: 25%;
  }

  .custom-col-xs-offset-2 {
    margin-left: 16.66666667%;
  }

  .custom-col-xs-offset-1 {
    margin-left: 8.33333333%;
  }

  .custom-col-xs-offset-0 {
    margin-left: 0;
  }
}

/*------------ header notification------------*/
.top-header-notification {
  z-index: 999;
}

.header-notification {
  opacity: 1 !important;
  color: #1f1f1f;
  width: 100%;
  height: 40px;
  background-color: #ffa637;
}

.header-notification .close {
  height: 40px;
  margin-right: 15px;
}

.header-notification-body {
  font-size: 20px;
  padding-left: 10px;
  padding-top: 5px;
  color: #fff;
}

@media (max-width: 576px) {
  .header-notification {
    height: 25px;
  }

  .header-notification .close {
    height: 25px;
  }

  .header-notification-body {
    font-size: 12px;
  }
}

.fare-rules-collapse .theme-search-results-item-extend-inner {
  max-height: 500px;
  overflow: auto;
}

@media (max-width: 786px) {
  .theme-search-results-item-extend-inner .inner_outbound {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .header-notification-body {
    font-size: 10px;
  }

  .extend-inner-padding.theme-search-results-item-extend-inner {
    padding-top: 5px;
    padding: 5px 15px;
  }

  .theme-search-results-item-extend-inner .inner_outbound {
    zoom: 80%;
  }

  .theme-search-results-item-extend-inner .inner_logo_block {
    padding: 0;
  }

  .theme-search-results-item-extend-inner .inner_logo_block .inner_logo_div {
    width: 25px;
    height: auto;
    margin: auto;
  }

  .theme-search-results-item-extend-inner .inner_logo_block .inner_logo_div .inner_logo {
    height: auto;
  }
}

/*Custom Tooltip */
.MuiTooltip-tooltip {
  background-color: #000 !important;
}

.MuiTooltip-arrow {
  color: #000 !important;
}

/*  */
/*.hide-toast {
  width: 100vw;
}*/

.hide-toast,
.hide-toast .close {
  animation-name: FadeInUp;
  animation-duration: 1000ms;
  height: 40px;
}

.fadeDown {
  animation-name: FadeInDown;
  animation-duration: 1000ms;
}

.header-notification .close:focus {
  outline: none;
}

@keyframes FadeInUp {
  0% {
    height: 40px;
  }

  100% {
    height: 0px;
    transform: translate(0, -40px);
  }
}

@keyframes FadeInDown {
  0% {}

  100% {
    transform: translate(0, 200px);
    display: none;
  }
}

.navbar-inner.nav,
.navbar-inner.nav * {
  transition: none !important;
  transform: none !important;
}

@media (max-width: 480px) {
  .btn-dark {
    padding: 10px;
    font-size: 12px !important;
  }

  .header-my-account {
    display: none;
  }

  .navbar-theme .navbar-nav>li>a.dropdown-toggle:before {
    display: none;
  }

  ul.dropdown-menu {
    right: 0;
    left: auto;
    top: 40px;
  }
}

@media (min-width: 768px) {
  i.fa.fa-user-circle-o.navbar-nav-item-user-icon {
    top: auto;
  }
}

.mx-auto {
  margin: 0 auto !important;
}

.pl-20 {
  padding-left: 20px;
}

.quick-pay-detail-card .form-group {
  margin-bottom: 0 !important;
}

.quick-pay-detail-card {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  transition: all 0.2s;
}

.quick-pay-detail-card:hover {
  box-shadow: 0 0 10px 0 #d1d1d1;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.terms-accepts .quick-pay-tick {
  width: 25px;
  height: 25px;
}

.quick-pay-btn {
  margin: 1.5rem;
  padding: 1rem 2rem;
  background-color: #f95b17;
  font-size: 16px;
}

@media (max-width: 480px) {
  .terms-accepts .quick-pay-tick {
    width: 65px;
    height: 35px;
  }

  .pay-online-form ._w-50pct {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .theme-custom-sidebar {
    display: none;
  }
}

.DayPickerKeyboardShortcuts_buttonReset {
  display: none !important;
}

.noshow_dest {
  display: none;
}

.show-dest {
  display: block;
}