.hero-area-bg {
  background: #0072a3;
}

.filter-button {
  position: fixed;
  color: #fff;
  background: #404040;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 10px;
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-button:hover,
.filter-button:focus {
  color: #fff;
}

.filter-icon {
  padding-left: 5px;
  font-size: 20px;
}

.flight-search-title-text {
  display: inline-grid;
}

.airport-name {
  font-size: 20px;
}

.arrow-icon-div {
  padding: 0 30px;
}

.arrow-icon {
  width: 20px;
  height: auto;
}

.flight-search-travellers {
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.flight-search-btn {
  padding: 11px 0 0;
  margin-left: 10px;
}

.edit-search-btn {
  background: none;
  border: none;
  text-decoration: underline;
  padding: 10px 10px 0 10px;
  font-size: 15px;
}

.edit-search-btn:hover,
.edit-search-btn:focus {
  color: #83daff;
  outline: none;
}

.inner_outbound {
  margin: auto;
}

.inner_detail {
  margin: auto;
  background: #fff;
}

.one-way-card-padding {
  padding: 46px 0;
}

.flight-search-title-departure {
  position: relative;
  padding-right: 45px;
}

.flight-search-title-arrival {
  padding-right: 15px;
}

.flight-search-title-departure,
.flight-search-title-arrival {
  max-width: 40%;
}

.flight-search-title-departure::before {
  content: "";
  display: block;
  height: 1px;
  background: #fff;
  position: absolute;
  top: auto;
  bottom: 2.5rem;
  width: 1.5rem;
  right: 1.25rem;
}

.flight-code {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  display: block;
}

.no-flights-result {
  display: flex;
  align-items: center;
  border: 1px solid #2dace3;
  padding: 20px;
  margin: 20px 0;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: #005e86;
}

@media (max-width: 325px) {
  .flight-search-title-departure::before {
    width: 0.5rem;
  }
}

@media (max-width: 786px) {
  ._pv-mob-50 {
    padding-top: 80px !important;
    padding-bottom: 0 !important;
  }

  ._mh-mob-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .padding-mob {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .one-way-card-padding {
    padding: 0;
  }

  .meta-section-padding {
    padding: 0 5px;
  }

  .mr-0-mobile-only {
    margin-right: 0px;
  }

  .pr-0-mobile-only {
    padding-right: 0px;
  }

  .ml-0-mobile-only {
    margin-left: 0px !important;
  }

  .pl-0-mobile-only {
    padding-left: 0px;
  }

  .border-0-mobile-only {
    border-bottom: 0px !important;
  }

  .pl-5-mobile-only {
    padding-left: 5px;
  }

  .pr-5-mobile-only {
    padding-right: 5px;
  }

  .theme-search-results-item-preview {
    padding: 0 !important;
  }

  .flight-section {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 15px;
  }

  .theme-search-results-item-flight-section-meta>p {
    margin: 0;
  }

  .theme-search-results-item-preview .row[data-gutter="20"]>[class^="col-"] {
    padding: 0;
    width: 100%;
  }

  .flight-section .theme-search-results-item-flight-section-airline-logo-wrap {
    height: auto;
  }

  .flight-section .theme-search-results-item-flight-section-airline-logo {
    width: 25px;
    margin-bottom: 5px;
  }

  .flight-section .theme-search-results-item-flight-section-path {
    height: 45px;
  }

  .flight-section .airline-name-para {
    font-size: 8px;
    position: relative;
    word-break: break-all;
    width: max-content;
  }

  .theme-search-results-item-flight-detail-items .theme-search-results-item-flight-details {
    margin-bottom: 0;
  }

  .flight-section .cancellation-chip {
    font-size: 10px;
    margin-top: 0;
  }

  .theme-search-results .ribbon-top-right span {
    left: -10px;
    top: 17px;
    font-size: 8px;
    padding: 6px 0;
  }

  .flight-book .book-price {
    font-size: 10px;
    margin-bottom: 5px;
    line-height: 120%;
  }

  .flight-book .theme-search-results-item-price-tag {
    font-size: 20px;
  }

  .flight-book .call-now-button {
    margin-bottom: 0;
  }

  .flight-book div.left_seat {
    padding: 0;
    font-size: 12px;
    display: inline-block;
    float: left;
    margin: 5px;
  }

  .flight-book .itenerary-text {
    margin: 0;
    font-size: 12px;
    display: inline-block;
    float: right;
    margin: 5px;
  }

  .theme-search-results-item-flight-section-meta-time {
    font-size: 20px;
    font-weight: normal;
  }

  .flight-section-airport-text {
    font-size: 14px;
  }

  .price-left {
    text-align: left;
    justify-content: left;
  }

  .flight-right-meta-text {
    text-align: right;
    margin-bottom: 10px;
  }

  .flight-left-meta-text {
    text-align: left;
    margin-bottom: 10px;
  }

  .fly-time-text {
    top: -8px !important;
  }

  .fly-time-text>p {
    font-size: 10px !important;
    margin-bottom: 0px !important;
  }

  .fly-time-text-content {
    display: grid;
  }

  .fly-time-icon-span {
    display: none;
  }

  .meta-date-text {
    font-size: 10px !important;
  }

  .flight-search-code-text {
    font-size: 12px !important;
  }

  .dot-line-mob {
    height: 1px;
    top: 24px;
  }

  .middle-path-icon {
    margin-bottom: 0px;
  }

  .flight-search-path-section {
    margin-bottom: 10px;
  }

  .theme-search-results-item-flight-section-path-line-dot {
    width: 6px;
    height: 6px;
    margin-bottom: 0;
  }

  .airplane-take-off {
    width: 14px;
  }

  .airplane-take-off-webonly {
    width: 14px;
    display: none;
  }

  .web-only {
    display: none;
  }

  .flight-book {
    padding: 5px 10px 20px 10px;
  }

  .flight-book .theme-search-results-item-price {
    margin-bottom: 5px;
  }

  .itenerary-text {
    margin-top: 5px;
    left: 0px !important;
    top: 0px !important;
    font-size: 12px !important;
  }

  .extend-inner-padding {
    padding: 25px;
  }

  .inner_duration {
    padding-right: 0;
    font-size: 12px;
  }

  .m_auto-layover {
    margin: auto;
  }

  .m_auto_layover_webonly {
    align-content: center;
    display: grid;
  }

  .m_auto_change_webonly {
    display: none;
  }

  .inner_exchange {
    display: none;
  }

  .inner_timer {
    display: none;
  }

  .inner_changeinfo {
    font-size: 10px;
    line-height: 19px;
  }

  .inner_layover_time {
    font-size: 14px;
    text-align: center;
  }

  .inner_divider {
    top: 26px !important;
  }

  .inner_logo_block {
    padding: 12px;
  }

  /* .theme-search-results-item-flight-section-airline-logo{
        width: 100% !important;
    } */
  .flight-search-title-departure,
  .flight-search-title-arrival {
    max-width: 40%;
  }

  .flight-search-title-departure {
    padding-right: 12px;
  }

  .flight-search-title-departure::before {
    bottom: 0.75rem;
    width: 0.5rem;
    right: 0.35rem;
  }

  .flight-search-title-arrival {
    padding-right: 8px;
  }

  .airport-name {
    font-size: 13px;
  }

  .flight-date {
    font-size: 11px;
  }

  .arrow-icon-div {
    padding: 0 15px;
  }

  .flight-search-btn {
    padding: 8px 0 0;
    margin-left: 15px;
  }

  .edit-search-btn {
    font-size: 11px;
    position: absolute;
    top: 3px;
    right: 0;
    padding-top: 0;
  }

  /* .width84{
        width: 84%;
    } */
  /*     .custom-sidebar-bg{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1040;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.24);
    } */
  .sm-h {
    display: block !important;
    z-index: 999;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin-bottom: 0px;
  }

  .mob-custom-sidebar {
    max-height: 100vh;
    overflow-y: scroll;
    width: 100vw;
    right: 0;
    position: fixed;
    top: 0;
    border-radius: 0;
    z-index: 1111;
  }

  .sticky-filter-button {
    width: calc(100% + 30px);
    padding: 40px 15px;
    margin: 0 -15px;
    background: white;
    position: sticky;
    bottom: 0;
    z-index: 5;
  }

  .sticky-filter-button button {
    position: relative;
  }

  .custom-sidebar {
    display: none;
  }

  /*     .custom-sidebar::before{
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.24);
    } */
}

/* .overflow-hidden {
    overflow: hidden !important;
} */

.custom-side-content {
  position: relative;
}

.mobile-only {
  display: none;
}

.custom-side-title {
  padding: 15px 0;
  margin: 0;
  font-weight: 600;
  border-bottom: 1px solid #d9d9d9;
  font-size: 18px;
  color: #676767;
}

.filter-title-color {
  color: #676767;
  font-weight: 600;
}

.clear-all-btn {
  font-size: 14px;
  text-decoration: underline;
  color: #2dace3;
  position: absolute;
  top: 16px;
  right: 0;
  cursor: pointer;
  text-align: right;
}

.clear-all-btn:hover {
  color: #1291c7;
}

.header-block.filters-clear-btn {
  padding: 16px 0;
}

.filters-clear-btn {
  font-size: 12px;
  color: #979797;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: underline;
  cursor: pointer;
  display: block;
}

.filters-clear-btn:hover {
  color: #2dace3;
}

.luggage-icon {
  font-size: 14px;
  color: #676767;
  background: #979797;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 100%;
  padding: 8px;
}

.theme-search-result-airport-name {
  font-size: 12px;
  margin-bottom: 0;
  color: #989898;
  text-align: center;
  margin-top: -8px;
}

.theme-search-result-airport-name p {
  margin-bottom: 0;
}

@media (max-width: 325px) {
  .meta-date-text {
    font-size: 9px !important;
  }

  .theme-search-result-airport-name {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .theme-search-result-airport-name {
    font-size: 8px;
    margin-top: 0;
    line-height: 12px;
    margin-top: 5px;
  }
}

.inner-clock img {
  width: 20px !important;
  height: 20px !important;
}

.inner_middle-layover {
  background: #fff;
  margin: auto;
}

.layover-div {
  width: 80%;
  border: 6px solid #ffffff;
  background-color: #ffd4b0;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50px;
  margin: auto !important;
  position: relative;
  z-index: 1;
}

@media (max-width: 480px) {
  .layover-div {
    padding-top: 0px;
  }
}

.inner_divider {
  position: absolute;
  top: 24px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #dadada;
  z-index: 0;
}

.image-icons {
  width: 20px;
  height: 20px;
  opacity: 0.7;
}

.image-icons:hover {
  opacity: 1;
}

.red-icon {}

.blue-icon {}

.inner_icons {
  display: grid;
}

.inner_image-icons {
  width: 16px;
  height: 16px;
  opacity: 0.7;
}

.inner_image-icons:hover {
  opacity: 1;
}

.flight-section-item {
  padding: 0 15px;
}

.flight-section-item:first-child {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px dashed #676767;
}

.inner-outbound-text {
  font-size: 16px;
  color: #333;
  padding: 0 5px;
  font-weight: 700;
}

/*-------Date range Picker style ------------*/
@media (max-width: 729px) {
  .DayPicker_focusRegion {
    display: inline-grid;
  }

  .xs-checked-bagge {
    justify-content: flex-end;
  }
}

/*--------help section--------*/
.help-section {
  min-height: 150px !important;
  margin-top: 10px;
}

.help-section p {
  padding-top: 16px;
  font-size: 16px;
  font-weight: 600;
}

.phone-icon {
  padding: 5px 0;
  width: 35px;
  margin-right: 10px;
}

.help-number-text {
  font-weight: 600;
  color: #fd5133;
  text-decoration: underline;
  font-size: 20px;
}

.help-section-text {
  font-weight: 400;
  color: #828282;
}

.book_with_us {
  display: block;
  text-align: center;
  padding-bottom: 10px;
}

.book-with-us-icon {
  width: 35px;
  height: 40px;
  padding-bottom: 5px;
}

/*.toast-notification-section{
    position: fixed;
    z-index: 999;
    bottom: 60px;
    margin: 10px;
}
.toast-notification-second-section{
    position: fixed;
    z-index: 999;
    bottom: 0;
    margin: 10px;
}*/

.font-black-toast {
  color: #000000 !important;
  text-align: center;
}

.fade-in {
  z-index: 11111;
  animation: fadeIn ease 2s;
  animation-duration: 15s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.toast-notification {
  padding: 15px 5px;
  background: #fff;
  opacity: 1 !important;
  box-shadow: 0 0 10px 0 #d1d1d1;
  border-radius: 4px;
  margin: 10px;
  transition: 0.2s;
}

.toast-notification-body {
  font-size: 13px;
  padding-left: 10px;
  padding-top: 5px;
  text-align: left;
  width: 275px;
}

@media (max-width: 600px) {
  .help-section {
    display: none;
  }

  .toast-notification {
    opacity: 0 !important;
  }
}

.airline-name-para {
  /* position: absolute;
  bottom: 1px;
  top: 76%;
  left: 50%; */
  /* transform: translateX(-50%); */
  font-size: 12px;
  width: fit-content;
  margin-bottom: 0;
}

.airline-name-para p {
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}

@media (max-width: 600px) {
  .airline-name-para p {
    white-space: normal;
    word-break: break-word;
  }
}

.airline-class-para {
  position: absolute;
  /* bottom: -11px; */
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  width: fit-content;
}