body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.err {
  font-size: small;
  color: red;
}
.asterisk {
  font-size: small;
  color: red;
}
.err_below {
  color: red;
}

@import './css/home.css';
@import './css/call-popup.css';
@import './css/flight-search-result.css';
@import './css/flight-details.css';
@import './css/thank-you.css';
@import './css/contact-us.css';
@import './css/holiday.css';
@import './css/header.css';
