.thankyou-section{
    text-align: center;
    padding-top: 25px;
}

.thankyou-section .thank-you-text{
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    color: coral;
}
.booking-section{
    margin-top: 20px;
    padding-bottom: 25px;
}
.Booking-confirmed-card{
    width: 65%;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    margin: auto;
    padding: 20px;
    box-shadow: 2px 0px 5px -1px #ccc;
}
.heading-text{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.heading-text .booking-confirmed-title{
    font-size: 20px;
    font-weight: 600;
}
.greeting-with-detail{
    display: block;
    font-size: 14px;
    color: #656565;
}

.booking-detail{
    border: 1px solid #f0f0f0;
    margin-top: 15px;
    display: flex;
}
.departing-flight-detail{
    padding-bottom: 20px;
}
.flight-detail-heading{
    color: #068dc8;
    padding: 10px 15px;
}
.return-flight-detail{
    padding-bottom: 20px;
    border-top: 1px dashed #f0f0f0;
}

.flight-detail-content{
    display: grid;
    text-align: center;
}
.flight-detail-content .code-and-time{
    font-weight: 600;
    font-size: 16px;
}
.flight-detail-content .flight-date{
    font-size: 14px;
}
.flight-detail-content .airport-name-text{
    font-size: 12px;
}

.travellers-detail-section, .payment-detail-section{
    padding: 15px 0;
}
.travellers-detail-section .traveller-title{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 16px;
}

.traveller-inner-content{
    display: flex;
    justify-content: space-between;
}
.payment-detail-section{
    border-top: 1px dashed #f0f0f0;
}
.payment-detail-section .payment-title{
    margin-bottom: 5px;
    font-size: 16px;
}
.payment-content{
text-align: center;
padding: 10px;
}
.payment-content p{
    font-size: 32px;
    font-weight: 600;
}
.payment-content span{
    font-size: 14px;
    color:#656565 ;
    font-style: italic;
}

@media (max-width:576px){
    .Booking-confirmed-card{
        width: 95%;
    }
    .booking-detail{
        display: block;
    }
    .flight-detail-content{
        padding: 0;
    }
    .flight-detail-content .code-and-time{
        font-size: 12px;
    }
    .flight-detail-content .flight-date{
        font-size: 11px;
    }
    .flight-detail-content .airport-name-text{
        font-size: 11px;
    }
}