.theme-hero-area-conatct-bg-wrap{
    height: 120px;
    position: relative;   
    text-align: center;
}
.theme-hero-area-conatct-bg-wrap .contact-us-title{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.contact-us-title .title-head{
    font-size: 32px;
    font-weight: 600;
    color: #fff;
}
.contact-us-title .title-tag{
    font-size: 12px;
    color: #fff;
}
.contact-card{
    position: relative;
    max-height: 165px;
}
.contact-card:hover{
    box-shadow: 2px 0px 5px -1px #ccc;
}
.contact-card-header{
    width: 55px;
    height: 55px;
    background: #fff0ea;
    border-radius: 100%;
    padding:6px;
    border: 4px solid #fff;
    box-shadow: 2px 0px 5px -1px #ccc;
    position: absolute;
    top: -20%;
    left: 43%;
}
.contact-card-header-icon{
        font-size: 35px;
        display: block;
        color: #0093d2;
}
.contact-card-title-text{
    font-weight: 600;
    margin-top: 20px;
}
.contact-card-body:hover{
    color: #0093d2;
}
.theme-contact-form{
    background: #fff;
    border-radius: 4px;
    padding: 25px;
    box-shadow: 2px 0px 5px -1px #ccc;
}

/*------------ About us /  ----------*/

.theme-blog-scetion{
    background: #fff;
    padding: 25px 20px;
    border-radius: 4px;
    box-shadow: 2px 0px 5px -1px #ccc;
}

/*------ theme custom sidebar-------*/
.theme-custom-sidebar{
    background: #fff;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    height: max-content;
    padding: 20px 16px;
}
.theme-custom-sidebar .sidebar-menu-item{
    padding: 15px;
    border-bottom: 1px solid #f0f0f0;
}
.theme-custom-sidebar .sidebar-menu-item:last-child{
    border-bottom: none;
}
.theme-custom-sidebar .sidebar-menu-item a{
    font-weight: 600;
    opacity: 0.8;
}
.theme-custom-sidebar .sidebar-menu-item:hover a{
    /* color: #2dace3; */
    opacity: 1;
}